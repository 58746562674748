import React, {useEffect, useState} from 'react'
import { ThemeProvider } from 'styled-components'
import BaseStyle from 'components/common/ui/base'
import theme from 'components/common/ui/theme'
import ReactGA from 'react-ga';
import { Route, Switch, Redirect, useHistory, withRouter } from "react-router-dom";
import { Home, Terms, Profile, Content, Account, Conversations, ProtectedRoute, Register, Login } from 'components/views'
import { RootAppComponentWithRoutes } from 'components/routes'
import { useSelector, useDispatch } from 'react-redux';
import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import ReportContent from 'components/common/ReportContent'
import Selectors from 'selectors';
import LoginSignup from 'components/auth'
import AuthHeader from 'components/common/Header/header-loggedin'
import Actions from 'actions';
import { Modal } from 'ui'
import './styles/main.scss'

function Root({history}) {
  ReactGA.initialize(process.env.GA_TRACKING_ID);
  const loggedIn = useSelector(Selectors.auth.loggedIn)
  const loginModalVisible = useSelector(Selectors.auth.modalVisible)
  const report = useSelector(Selectors.settings.reportData)
  const messagedUser = useSelector(Selectors.auth.messagedUser)
  const dispatch = useDispatch()
  const isHome = history.location.pathname === '/'
  const [isHeaderVisible, setHeaderVisible] = useState(false)

  useEffect(() => {
    dispatch(Actions.profile.myProfile.load.trigger())
  }, [dispatch])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [history])


  const hideHeader = slug => history.location.pathname.split("/").pop() !== slug



  return (
    <ThemeProvider theme={theme}>
      <BaseStyle>
        <>
          {(!isHome && 
            hideHeader('checkout') && 
            hideHeader('confirmation') && 
            hideHeader('register') && 
            hideHeader('login')) && (
            <Header
              bg="white"
              smallLogo
              flex={1}
              row
              hideLogo
              hideNotice
              authHeader
              url=""
              isHome={isHome}
              justifyContent="space-between"
              alignItems="flex-start"
              position="relative"
              pr={6}
              pl={{
                mobile: 3,
                desktop: 6
              }}
              pt={{
                mobile: 0,
                desktop: 4
              }}
              pb={{
                mobile: 3,
                desktop: 0
              }}
            />
          )}
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/terms" component={Terms} />
            <Route path="/s" component={Content} />
            {loggedIn && <Redirect exact from="/login" to="/conversations" />}
            {loggedIn && <Redirect exact from="/register" to="/conversations" />}
            <Route path="/register" exact component={Register} />
            <Route path="/login" exact component={Login} />
            <ProtectedRoute path="/account" loggedIn={loggedIn} component={Account} />
            <ProtectedRoute path="/conversations" loggedIn={loggedIn} component={Conversations} />
            <Profile/>
          </Switch>
          {/* <Footer flex={1} mt={3} pb={10} textAlign="center" /> */}
          <Modal isVisible={loginModalVisible} onClose={() => {
            dispatch(Actions.auth.showModal({visible: false}))
          }}>
            <LoginSignup 
              callback={() => {
                dispatch(Actions.auth.showModal({visible: false}))
                setTimeout(()=> {
                  if(messagedUser) {
                    history.push(`/conversations/${messagedUser}`)
                  } else {
                    history.push(`/conversations`)
                  }
                }, 500)
              }}
              title={{
                login: 'login to connect with creators',
                signup: 'sign up to connect with creators'
              }}
            />
          </Modal>

          <Modal 
            isVisible={report.visible} 
            onClose={() => {
              dispatch(Actions.settings.reportModal({
                visible: false
              }))
            }}
          >
            <ReportContent {...report}  />
          </Modal>
        </>
      </BaseStyle>
    </ThemeProvider>
  );
}

export default withRouter(Root);